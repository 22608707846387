/* Importing React and other classes which are used to delay loading for async functions as well as state management */
import React, { useEffect, useState } from 'react';
/* Importing stylesheet */
import "./components.css";
/* Importing navigation bar component */
import Navbar from './Navbar';
/* Importing React Charts which are used to display the results of analysis graphically to an end user */
import { AxisOptions, Chart } from 'react-charts';
/* Importing useLocation to get data from previous component */
import { useLocation } from 'react-router-dom';
/* Importing Axios to assist with API requests */
import axios from 'axios';

/* Class to handle presentation of analysis (in cases where a comparison method has been selected) to the end user */

/**
 * Axios usage following get started documentation below
 * 
 * “Axios,” axios-http.com. https://axios-http.com/
 */

/* Defining component */
function ReviewAnalysis() {
    /* useState used in conjunction with useEffect to delay the display of analysis until some computations have completed */
    const [computed, setComputed] = useState(true);
    /* useState to persist data from character n-gram discrepancy computation as storing in array does not persist with useEffect,
       character bigrams discrepancies

       Like most of the instantiations in this file, the character B has been applied to what seem like 
       duplicates to prevent writing the word comparison many, many times
    */
    const [cB, setCB] = useState<any>([]);
    const [cT, setCT] = useState<any>([]);
    const [cU, setCU] = useState<any>([]);
    const [cBB, setCBB] = useState<any>([]);
    const [cTB, setCTB] = useState<any>([]);
    const [cUB, setCUB] = useState<any>([]);

    /* Getting the location to access set state variables in Presets or Upload component */
    const location = useLocation();
    /* Obtaining the comparison method then making it more presentable for output */
    let comparison = location.state?.comparison;
    if (comparison === "chatgptPrompt") {
      comparison = "ChatGPT prompt";
    }
    else if (comparison === "chatgptThemedEssay") {
        comparison = "ChatGPT themed essay";
    } else if (comparison === "freeTextEntry") {
        comparison = "Free text entry";
    } else if (comparison === "fileUpload") {
        comparison = "File upload";
    } else if (comparison === "noComparison") {
        comparison = "No comparison";
    }
    /* Using .trim() on the obtained profile to remove \n*/
    let comparisonComputeTrimmed = location.state?.comparisonCompute.trim();
    /* Removing occurences of ' for " */
    comparisonComputeTrimmed = comparisonComputeTrimmed.replace(/'/g, '"');
    /* Parsing as JSON to have the complete set of metrics from the computed profile */
    const comparisonCompute = JSON.parse(comparisonComputeTrimmed);
    
    /* Above steps are replicated for the alternate sample */    
    let presetComputeTrimmed = location.state?.presetCompute.trim();
    presetComputeTrimmed = presetComputeTrimmed.replace(/'/g, '"');
    const presetCompute = JSON.parse(presetComputeTrimmed);

    /* Obtaining the text windows from the samples */
    const computeTextWindow = location.state?.computeTextWindow;
    const presetTextWindow = location.state?.presetTextWindow;

    /* Obtaining the sample name */
    const sample = location.state?.sample;

    /* Obtaining the output from sliding window profile computation */
    let sliding = location.state?.sliding;
    /* Removing the first 2 characters */
    sliding = sliding.slice(2);
    /* Splitting the content on ], to get the computed profiles */
    let x = sliding.split('],');
    /* Splitting the content on }, to get an array of individual sets of profiles */
    let d = x[0].split('},');

    /* Instantiating windows as the unsplit resulting windows of text from above split */
    let windows = x[1];
    /* Remove first 2 undesired characters */
    windows = windows.slice(2);
    /* Remove the final 3 characters */
    windows = windows.slice(0, -3);
    /* Splitting windows into individual text windows */
    let e = windows.split('\',');
    if (sample !== 'Preset') {
      for (let x=0; x < e.length; x++) {
        if (x === 0) {
          e[x] = e[x].slice(1);
        } else {
          e[x] = e[x].slice(2);
        }
        if (x === 3) {
          e[x] = e[x].slice(0, -1);
        }
      }
    } else {
      e = e.slice(1);
      e = e.slice(0, -1);
      e = windows.split('",')
      for (let x=0; x < e.length; x++) {
        if (x === 0) {
          e[x] = e[x].slice(1);
        } else {
          e[x] = e[x].slice(2);
        }
        if (x === 3) {
          e[x] = e[x].slice(0, -1);
        }
      }
    }

    let sequence = ['characterBigrams', 'characterTrigrams', 'characterUnigrams'];
    let characterBigrams: any[] = [];
    let characterTrigrams: any[] = [];
    let characterUnigrams: any[] = [];
    /* Creating array to store promises which are asynchronous actions waiting to be completed */
    let promises: any = [];
    /* For each window of text and for each of the above sequences, compute character level n-gram analysis */
    for (let x=0; x < e.length; x++) {
      for (let y=0; y < sequence.length; y++) {
        /* Set this as a promise since the analysis can take time and in turn the promise will delay component output until
           all the computations have been completed

           Here I am requesting the computation from n-grams.php API and passing the current text window and the desired n-gram length
        */
        let promise = axios.get("api/n-grams.php", {
          params: {
            window: e[x],
            sequence: sequence[y]
          }
        })
        /* Once the computation has been completed then.. */
        .then(response => {
          /* Instantiate ngrams as the result of computation which will be the 10 most common n-grams and frequencies as key, pair */
          let ngrams = response.data.ngrams;
          /* As this comes in as a set in FreqDist form we want to convert to JSON format for easier access,
             removing occurences of ', (), and [] then parsing as JSON before pushing to relevant array
          */
          ngrams = ngrams.replace(/'/g, '"').replace(/\(/g, '[').replace(/\)/g, ']');
          /* If sequence index is then.. */
          if (y === 0) {
            characterBigrams.push(JSON.parse(ngrams));
          } else if (y === 1) {
            characterTrigrams.push(JSON.parse(ngrams));
          } else {
            characterUnigrams.push(JSON.parse(ngrams));
          }
        })
        .catch(error => {
          console.error(error);
        });
        /* Add this code execution as a promise so the end user does not see incomplete analysis on the output component page */
        promises.push(promise);
      }
    }

    let characterBigramsB: any[] = [];
    let characterTrigramsB: any[] = [];
    let characterUnigramsB: any[] = [];
    /* For each window of text and for each of the above sequences, compute character level n-gram analysis */
    for (let x=0; x < e.length; x++) {
      for (let y=0; y < sequence.length; y++) {
        /* Set this as a promise since the analysis can take time and in turn the promise will delay component output until
           all the computations have been completed

           Here I am requesting the computation from n-grams.php API and passing the current text window and the desired n-gram length
        */
        let promise = axios.get("api/n-grams.php", {
          params: {
            window: e[x],
            sequence: sequence[y]
          }
        })
        /* Once the computation has been completed then.. */
        .then(response => {
          /* Instantiate ngrams as the result of computation which will be the 10 most common n-grams and frequencies as key, pair */
          let ngrams = response.data.ngrams;
          /* As this comes in as a set in FreqDist form we want to convert to JSON format for easier access,
             removing occurences of ', (), and [] then parsing as JSON before pushing to relevant array
          */
          ngrams = ngrams.replace(/'/g, '"').replace(/\(/g, '[').replace(/\)/g, ']');
          /* If sequence index is then.. */
          if (y === 0) {
            characterBigramsB.push(JSON.parse(ngrams));
          } else if (y === 1) {
            characterTrigramsB.push(JSON.parse(ngrams));
          } else {
            characterUnigramsB.push(JSON.parse(ngrams));
          }
        })
        .catch(error => {
          console.error(error);
        });
        /* Add this code execution as a promise so the end user does not see incomplete analysis on the output component page */
        promises.push(promise);
      }
    }

    /* Instantiate arrays to store the most common character level n-grams and frequencies */
    let freqCharacterBigrams: any[] = [];
    let freqCharacterTrigrams: any[] = [];
    let freqCharacterUnigrams: any[] = [];
    let freqCharacterBigramsB: any[] = [];
    let freqCharacterTrigramsB: any[] = [];
    let freqCharacterUnigramsB: any[] = [];

    /* Taking advantage of useEffect() to make function asynchronous and allow the output of the component to be delayed until 
       this asynchronous function has completed
    */
    useEffect(() => {
      /* Declaring function as computeCharacterNgrams so can easily call below */
      const computeCharacterNgrams = async () => {
        /* Waiting for all promises in the promises = [] array to be completed before continuing */
        await Promise.all(promises)
          /* Once complete then.. */
          .then(() => {
            /* Instantiate temporary arrays to store the results of character level n-gram discrepancy analysis,
               this has to be done over global array as useEffect does not persist the array data globally but I 
               found an alternative solution with useState
            */
            let discrepanciesCharacterBigrams: any[] = [];
            let discrepanciesCharacterTrigrams: any[] = [];
            let discrepanciesCharacterUnigrams: any[] = [];
            let discrepanciesCharacterBigramsB: any[] = [];
            let discrepanciesCharacterTrigramsB: any[] = [];
            let discrepanciesCharacterUnigramsB: any[] = [];

            /* Instantiating a set to keep track of what keys we have seen already then clearing this for each n-gram length */
            let iterated = new Set();
            /* For the arrays of character bigrams */
            for (let x=0; x < characterBigrams.length; x++) {
              /* For the length of each array (10) */
              for (let y=0; y < characterBigrams[x].length; y++) {
                /* If the character bigram has not been seen yet */
                if (!iterated.has(characterBigrams[x][y][0])) {
                  /* Push the key and value to the array and add the key to the set of iterated keys so we don't create 
                     multiple of the same key which would be annoying
                  */
                  freqCharacterBigrams.push([characterBigrams[x][y][0], characterBigrams[x][y][1]]);
                  iterated.add(characterBigrams[x][y][0]);
                  /* If we have already seen the key */
                } else {
                  /* Find the index within the array where the key is present then.. */
                  const element = freqCharacterBigrams.findIndex(element => element[0] === characterBigrams[x][y][0]);
                  /* Increment the value of the key at that position by the value of the key in the character bigrams array */
                  freqCharacterBigrams[element][1] += characterBigrams[x][y][1];
                }
              }
            }
            /* Repeat the process for character trigrams */
            for (let x=0; x < characterTrigrams.length; x++) {
              for (let y=0; y < characterTrigrams[x].length; y++) {
                if (!iterated.has(characterTrigrams[x][y][0])) {
                  freqCharacterTrigrams.push([characterTrigrams[x][y][0], characterTrigrams[x][y][1]]);
                  iterated.add(characterTrigrams[x][y][0]);
                } else {
                  const element = freqCharacterTrigrams.findIndex(element => element[0] === characterTrigrams[x][y][0]);
                  freqCharacterTrigrams[element][1] += characterTrigrams[x][y][1];
                }
              }
            }
            /* Repeat the process for character unigrams */
            for (let x=0; x < characterUnigrams.length; x++) {
              for (let y=0; y < characterUnigrams[x].length; y++) {
                if (!iterated.has(characterUnigrams[x][y][0])) {
                  freqCharacterUnigrams.push([characterUnigrams[x][y][0], characterUnigrams[x][y][1]]);
                  iterated.add(characterUnigrams[x][y][0]);
                } else {
                  const element = freqCharacterUnigrams.findIndex(element => element[0] === characterUnigrams[x][y][0]);
                  freqCharacterUnigrams[element][1] += characterUnigrams[x][y][1];
                }
              }
            }
            iterated.clear();
            /* Repeat the process for alternate character bigrams */
            for (let x=0; x < characterBigramsB.length; x++) {
              for (let y=0; y < characterBigramsB[x].length; y++) {
                if (!iterated.has(characterBigramsB[x][y][0])) {
                  freqCharacterBigramsB.push([characterBigramsB[x][y][0], characterBigramsB[x][y][1]]);
                  iterated.add(characterBigramsB[x][y][0]);
                } else {
                  const element = freqCharacterBigramsB.findIndex(element => element[0] === characterBigramsB[x][y][0]);
                  freqCharacterBigramsB[element][1] += characterBigramsB[x][y][1];
                }
              }
            }
            /* Repeat the process for alternate character trigrams */
            for (let x=0; x < characterTrigramsB.length; x++) {
              for (let y=0; y < characterTrigramsB[x].length; y++) {
                if (!iterated.has(characterTrigramsB[x][y][0])) {
                  freqCharacterTrigramsB.push([characterTrigramsB[x][y][0], characterTrigramsB[x][y][1]]);
                  iterated.add(characterTrigramsB[x][y][0]);
                } else {
                  const element = freqCharacterTrigramsB.findIndex(element => element[0] === characterTrigramsB[x][y][0]);
                  freqCharacterTrigramsB[element][1] += characterTrigramsB[x][y][1];
                }
              }
            }
            /* Repeat the process for alternate character unigrams */
            for (let x=0; x < characterUnigramsB.length; x++) {
              for (let y=0; y < characterUnigramsB[x].length; y++) {
                if (!iterated.has(characterUnigramsB[x][y][0])) {
                  freqCharacterUnigramsB.push([characterUnigramsB[x][y][0], characterUnigramsB[x][y][1]]);
                  iterated.add(characterUnigramsB[x][y][0]);
                } else {
                  const element = freqCharacterUnigramsB.findIndex(element => element[0] === characterUnigramsB[x][y][0]);
                  freqCharacterUnigramsB[element][1] += characterUnigramsB[x][y][1];
                }
              }
            }
            /* Sort each array of key, value pairs so that the highest values descend */
            freqCharacterBigrams.sort((x, y) => y[1] - x[1]);
            freqCharacterTrigrams.sort((x, y) => y[1] - x[1]);
            freqCharacterUnigrams.sort((x, y) => y[1] - x[1]);
            freqCharacterBigramsB.sort((x, y) => y[1] - x[1]);
            freqCharacterTrigramsB.sort((x, y) => y[1] - x[1]);
            freqCharacterUnigramsB.sort((x, y) => y[1] - x[1]);

            /* Iterate over the length of the character bigrams array and count how many of the window n-gram lengths match
               against the 10 overall most common character bigrams in the text computed from all of the windows and count this
               result 
            */
            for (let x=0; x < characterBigrams.length; x++) {
              let count = 0;
              /* Every iteration resetting the set to ensure we're setup to go again */
              let iterated = new Set();
              for (let y=0; y < characterBigrams[x].length; y++) {
                /* Iterating to 10 as we shouldn't have more than 10 values in the most common */
                for (let z=0; z < 10; z++) {
                  /* If the key matches a key in the top 10 and we haven't seen the key before then increment count by 1 */
                  if ((characterBigrams[x][y][0] === freqCharacterBigrams[z][0]) && (!iterated.has(characterBigrams[x][y][0]))) {
                    count += 1;
                  }
                }
                /* Making sure we've set that we have seen the key before */
                iterated.add(characterBigrams[x][y][0]); 
              }
              /* Push the computed discrepancies to the array, count is 0 at the start for cases where no discrepancy exists */
              discrepanciesCharacterBigrams.push(count);
            }
            /* Repeat for character trigrams */
            for (let x=0; x < characterTrigrams.length; x++) {
              let count = 0;
              let iterated = new Set();
              for (let y=0; y < characterTrigrams[x].length; y++) {
                for (let z=0; z < 10; z++) {
                  if ((characterTrigrams[x][y][0] === freqCharacterTrigrams[z][0]) && (!iterated.has(characterTrigrams[x][y][0]))) {
                    count += 1;
                  }
                }
                iterated.add(characterTrigrams[x][y][0]); 
              }
              discrepanciesCharacterTrigrams.push(count);
            }
            /* Repeat for character unigrams */
            for (let x=0; x < characterUnigrams.length; x++) {
              let count = 0;
              let iterated = new Set();
              for (let y=0; y < characterUnigrams[x].length; y++) {
                for (let z=0; z < 10; z++) {
                  if ((characterUnigrams[x][y][0] === freqCharacterUnigrams[z][0]) && (!iterated.has(characterUnigrams[x][y][0]))) {
                    count += 1;
                  }
                }
                iterated.add(characterUnigrams[x][y][0]); 
              }
              discrepanciesCharacterUnigrams.push(count);
            }
            /* Now as I previously mentioned this function is asynchronous meaning it will complete in the background until done
               therefore storing the discrepancies in respective arrays globally will not work as the function will call itself
               recursively until the computed variable is set to false indicating computing is done
            */
            setCB(discrepanciesCharacterBigrams);
            setCT(discrepanciesCharacterTrigrams);
            setCU(discrepanciesCharacterUnigrams);

            /* Repeat the process for alternate character bigrams */
            for (let x=0; x < characterBigramsB.length; x++) {
              let count = 0;
              let iterated = new Set();
              for (let y=0; y < characterBigramsB[x].length; y++) {
                for (let z=0; z < 10; z++) {
                  if ((characterBigramsB[x][y][0] === freqCharacterBigramsB[z][0]) && (!iterated.has(characterBigramsB[x][y][0]))) {
                    count += 1;
                  }
                }
                iterated.add(characterBigramsB[x][y][0]); 
              }
              discrepanciesCharacterBigramsB.push(count);
            }
            /* Repeat the process for alternate character trigrams */
            for (let x=0; x < characterTrigramsB.length; x++) {
              let count = 0;
              let iterated = new Set();
              for (let y=0; y < characterTrigramsB[x].length; y++) {
                for (let z=0; z < 10; z++) {
                  if ((characterTrigramsB[x][y][0] === freqCharacterTrigramsB[z][0]) && (!iterated.has(characterTrigramsB[x][y][0]))) {
                    count += 1;
                  }
                }
                iterated.add(characterTrigramsB[x][y][0]); 
              }
              discrepanciesCharacterTrigramsB.push(count);
            }
            /* Repeat the process for alternate character unigrams */
            for (let x=0; x < characterUnigramsB.length; x++) {
              let count = 0;
              let iterated = new Set();
              for (let y=0; y < characterUnigramsB[x].length; y++) {
                for (let z=0; z < 10; z++) {
                  if ((characterUnigramsB[x][y][0] === freqCharacterUnigramsB[z][0]) && (!iterated.has(characterUnigramsB[x][y][0]))) {
                    count += 1;
                  }
                }
                iterated.add(characterUnigramsB[x][y][0]); 
              }
              discrepanciesCharacterUnigramsB.push(count);
            }
            setCBB(discrepanciesCharacterBigramsB);
            setCTB(discrepanciesCharacterTrigramsB);
            setCUB(discrepanciesCharacterUnigramsB);
            /* Now as I previously mentioned this function is asynchronous meaning it will complete in the background until done
               therefore storing the discrepancies in respective arrays globally will not work as the function will call itself
               recursively until the computed variable is set to false indicating computing is done.
               
               To persist data I have used useState as seen below, once data has been persisted the useState computed is set to false
               and only then will the component load with data that is accessible otherwise a temporary white page will show and a small
               delay in loading will be present
            */
            setComputed(false);
          }
          )
          .catch(error => {
            console.error(error);
          }
        );
        }
      /* Calling the asynchronous function */
      computeCharacterNgrams();
    }, []);

    /* If the function has not completed as indicated by the useState then return white page until complete,
       when computation is complete the component will load and more functionality will execute with the data
       that is now accessible   
    */
    if (computed) {
      return null;
      /* Proceed with the rest of the computation and loading */
    } else {
      /* Array to store computed profiles of sliding text windows */
      const compslide: any[] = [];
      /* Everytime a new metric is added this array must be updated */
      const metrics = ['avgArchaicWords', 'avgFunctionWords', 'avgMisspeltWords', 'avgTokenLength', 'avgSentenceLength', 'bigrams', 'characterBigrams', 'characterTrigrams', 'characterUnigrams', 'lexicalRichness', 'sentences', 'tokens', 'trigrams', 'unigrams'];
      /* More presentable versions of the metrics used in component output to end user */
      const metricsFancy = ['Average Archaic Words', 'Average Function Words', 'Average Misspelt Words', 'Average Token Length', 'Average Sentence Length', 'Bigrams', 'Character Bigrams', 'Character Trigrams', 'Character Unigrams', 'Lexical Richness', 'Sentences', 'Tokens', 'Trigrams', 'Unigrams'];
      /* Weights apply to metrics and assist with giving a threshold for up to what low and high bound would we expect metrics 
         to fall within and if they are outside of this (by a realistic amount not extreme) then we have a discrepancy
      */     

      /* With the obtained sliding text windows from API, parse as JSON after processing to remove \n and occurences of ' instead of " */
      for (let x=0; x < d.length; x++) {
        if (x != d.length - 1) {
          d[x] = d[x] + '}';
        }
        d[x] = d[x].trim();
        d[x] = d[x].replace(/'/g, '"');
        compslide.push(JSON.parse(d[x]));
      }

      /* To indicate whenever a discrepancy has occurred, with the exception of character level n-gram analysis,
         I replicate the arrays of data and then change values at indexs to indicate a discrepancy over instantiating
         new arrays and then needing a longer method to establish what has a discrepancy and where
      */ 

      /* Discrepancies for usual metrics */
      let discrepancies = JSON.parse(JSON.stringify(compslide));
      /* Instantiated arrays to store the minimum and maximum values of each metric */
      let minimums = JSON.parse(JSON.stringify(compslide));
      let maximums = JSON.parse(JSON.stringify(compslide));
      /* Instantiated arrays to store discrepancies with the above */      
      let minimumsDiscrepancies = JSON.parse(JSON.stringify(minimums));
      let maximumsDiscrepancies = JSON.parse(JSON.stringify(maximums));
      /* Instantiated array to hold the averages of metrics */
      const averages: any[] = [];

      /* For each metric set the index value of arrays to 0 */
      for (let x=0; x < metrics.length; x++) {
        averages[x] = 0;
      }

      /* Calculate the average value of each metric */
      for (let x=0; x < metrics.length; x++) {
        for (let y=0; y < compslide.length; y++) {
          averages[x] = averages[x] + (compslide[y][metrics[x]]);
        }
        averages[x] = averages[x] / compslide.length;
      }

      /* For each metric compute the minimum value across the text windows */
      for (let x=0; x < metrics.length; x++) {
        let min = compslide[0][metrics[x]];
        for (let y=0; y < compslide.length; y++) {
          if (compslide[y][metrics[x]] < min) {
            min = compslide[y][metrics[x]];
          }
        }
        for (let z=0; z < compslide.length; z++) {
          if (compslide[z][metrics[x]] === min) {
            /* Set the minimums array value at this index to 'Minimum' */
            minimums[z][metrics[x]] = 'Minimum';
            /* Break once found to avoid duplicates */
            break;
          }
        }
      }

      for (let x=0; x < metrics.length; x++) {
        let max = compslide[0][metrics[x]];
        for (let z=0; z < compslide.length; z++) {
          if (compslide[z][metrics[x]] > max) {
            max = compslide[z][metrics[x]];
          }
        }
        for (let z=0; z < compslide.length; z++) {
          if (compslide[z][metrics[x]] === max) {
            /* Set the minimums array value at this index to 'Maximum' */
            maximums[z][metrics[x]] = 'Maximum';
            /* Break once found to avoid duplicates */
            break;
          }
        }
      }

      /* Extreme weights apply to the minimum and maximum values of metrics and are used to act as a multiplier for thresholds to 
         determine if a value is quite substantially less or more than what we would expect and if so then we likely have a discrepancy
      */
      const extremeWeights = [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5];
      /* Weights apply to metrics and assist with giving a threshold for up to what low and high bound would we expect metrics 
        to fall within and if they are outside of this (by a realistic amount not extreme) then we have a discrepancy
      */
      const weights = [1.4, 0.28, 3, 0.09, 0.48, 0.17, 0.11, 0.12, 0.11, 0.15, 0.37, 0.03, 0.17, 0.17];

      const averageLength = (presetCompute.tokens + comparisonCompute.tokens) / 2;

      /* Now we are going to identify for each metric any discrepancies that have arisen */
      for (let x=0; x < metrics.length; x++) {
        /* For all computed profiles in each text window */
        for (let y=0; y < compslide.length; y++) {
          /* If the value of the metric in question is more than the average value of the metric across
            all text windows multiplied by a threshold or if the value is less than the average value
            multiplied by a threshold for that specific value then..
          */          
          if ((compslide[y][metrics[x]] / averageLength > (averages[x] / averageLength * (1 + weights[x]))) || (compslide[y][metrics[x]] / averageLength < (averages[x] / averageLength * (1 - weights[x])))) {
              /* We set the cloned compslide array to indicate there is a discrepancy of this metric within such window */
            discrepancies[y][metrics[x]] = 'Discrepancy';
          }

          /* Find the minimum value of the metric in any window */
          let minimumValue = 0;
          for (let z=0; z < compslide.length; z++) {
            if (minimums[z][metrics[x]] === 'Minimum') {
              minimumValue = compslide[z][metrics[x]];
              break;
            }
          }

          /* Find the maximum value of the metric in any window */
          let maximumValue = 0;
          for (let z=0; z < compslide.length; z++) {
            if (maximums[z][metrics[x]] === 'Maximum') {
              maximumValue = compslide[z][metrics[x]];
              break;
            }
          }

          /* If the value of the metric is less than the minimum value in any window multiplied by
            an appropriate extreme weight to indicate there is a substantial discrepancy then..
          */
          if (compslide[y][metrics[x]] < (minimumValue * (1 - extremeWeights[x]))) {
            /* Indicate that there is a discrepancy */
            minimumsDiscrepancies[y][metrics[x]] = 'ExtremeMinimumDiscrepancy';
          }

          if ((compslide[y][metrics[x]] > (maximumValue * (1 + extremeWeights[x])))) {
            maximumsDiscrepancies[y][metrics[x]] = 'ExtremeMaximumDiscrepancy';
          }
        }
      }

      /* Key array that stores HTML output of discrepancy analysis */
      const textWindows = [];
      /* Instantiating booleans that will be checks for if a certain discrepancy has occurred 
         and then if so we would like to highlight this to the end user
      */
      let discrepancy = false;
      let minimumDiscrepancy = false;
      let maximumDiscrepancy = false;

      /* Across all windows of text */
      for (let x=0; x < e.length; x++) {
        let found = false;
        for (let z=0; z < metrics.length; z++) {
          /* If we find a discrepancy then update found and discrepancy */
          if (discrepancies[x][metrics[z]] == 'Discrepancy') {
            found = true;
            discrepancy = true;
          }
          if (minimumsDiscrepancies[x][metrics[z]] == 'ExtremeMinimumDiscrepancy') {
            discrepancy = true;
            minimumDiscrepancy = true;
          }
          if (maximumsDiscrepancies[x][metrics[z]] == 'ExtremeMaximumDiscrepancy') {
            discrepancy = true;
            maximumDiscrepancy = true;
          }
        }

        /* Identical logic for character level n-gram discrepancy analysis */
        let foundCharB = false;
        let foundCharT = false;
        let foundCharU = false;

        let threshold = 4;
        if (cBB[x] < threshold) {
          discrepancy = true;
          foundCharB = true;
        } else if (cTB[x] < threshold) {
          discrepancy = true;
          foundCharT = true;
        } else if (cUB[x] < threshold) {
          discrepancy = true;
          foundCharU = true;
        }

        /* If we have a discrepancy */
        if (found || foundCharB || foundCharT || foundCharU) {
          /* Queue up a new HTML div that will indicate this under profileStatistics,
            this also outputs the text window content 
          */
          textWindows.push(
            <div className='profileStatistics'>
              <b>Window {x + 1}</b>
              <div className="profileStatisticsDiscrepancy">
                <p>{e[x]}</p>
              </div>
              <b>Discrepancies</b>
            </div>
          )
          /* For each metric */
          for(let z=0; z < metrics.length; z++) {
            /* Prepare HTML output displaying what the metric is and the value */
            let metric = <p className='profileStatisticsDiscrepancy'>{metricsFancy[z]}: <b>{compslide[x][metrics[z]] / averageLength}</b></p>;
            /* Expected value range of lowest to highest */
            let output = <p>(Expected: {averages[z] / averageLength * (1 - weights[z])} - {averages[z] / averageLength * (1 + weights[z])})</p>;
            /* If in the discrepancies array we detect a discrepancy or in the minimum or maximum then.. */
            if ((discrepancies[x][metrics[z]] == 'Discrepancy') || (minimumsDiscrepancies[x][metrics[z]] == 'ExtremeMinimumDiscrepancy') || (maximumsDiscrepancies[x][metrics[z]] == 'ExtremeMaxmimumDiscrepancy')) {
              /* If the discrepancy is a minimum then obtain the minimum value range and output this */
              if (minimumsDiscrepancies[x][metrics[z]] == 'ExtremeMinimumDiscrepancy') {
                let minimumValue = 0;
                for (let a=0; a < compslide.length; a++) {
                  if (minimums[a][metrics[z]] === 'Minimum') {
                    minimumValue = compslide[a][metrics[z]];
                    break;
                  }
                }
                metric = <p className='profileStatisticsDiscrepancy'>Minimum {metricsFancy[z]}: <b>{compslide[x][metrics[z]]}</b></p>;
                output = <p>(Expected more than: {minimumValue * (1 - extremeWeights[z])})</p>;
              } 
              /* If the discrepancy is a maximum then obtain the maximum range and output this */
              if (maximumsDiscrepancies[x][metrics[z]] == 'ExtremeMaximumDiscrepancy') {
                let maximumValue = 0;
                for (let b=0; b < compslide.length; b++) {
                  if (maximums[b][metrics[z]] === 'Maximum') {
                    maximumValue = compslide[b][metrics[z]];
                    break;
                  }
                }
                metric = <p className='profileStatisticsDiscrepancy'>Maximum {metricsFancy[z]}: <b>{compslide[x][metrics[z]]}</b></p>;
                output = <p>(Expected less than: {maximumValue * (1 + extremeWeights[z])})</p>;
              }
              /* Here we push to the array of HTML code divs our output that has been built above */
              textWindows.push(
                <div className='profileStatistics'>
                  {metric}
                  {output}
                </div>
              )
            }
          }
          /* Similar logic with character level n-gram discrepancies */
          if (foundCharB) {
            textWindows.push(
              <div className='profileStatistics'>
                <p className='profileStatisticsDiscrepancy'>Matching Character Bigrams (Top 10): <b>{cBB[x]}</b></p>  
                <p>(Expected: {threshold} - 10)</p>                
              </div>
            )
          } else if (foundCharT) {
            textWindows.push(
              <div className='profileStatistics'>
                <p className='profileStatisticsDiscrepancy'>Matching Character Trigrams (Top 10): <b>{cTB[x]}</b></p>  
                <p>(Expected: {threshold} - 10)</p>                  
              </div>
            )
          } else if (foundCharU) {
            textWindows.push(
              <div className='profileStatistics'>
                <p className='profileStatisticsDiscrepancy'>Matching Character Unigrams (Top 10): <b>{cUB[x]}</b></p>  
                <p>(Expected: {threshold} - 10)</p>  
              </div>
            )
          }
        } else {
          textWindows.push(
            <div className='profileStatistics'>
              <b>Window {x + 1}</b>
              <div className="profileStatisticsNoDiscrepancy">
                <p>{e[x]}</p>
              </div>
            </div>
          )
        }
      }

      /* Instantiate the small text that accompanies the analysis */
      let discrepancyText = "";
      /* Dependant on the state of discrepancy then set text.. */
      if (discrepancy == false) {
        discrepancyText = "Sample has consistent writing profile"
      } else {
        discrepancyText = "Sample has at least one discrepancy in writing profile, please review the suspected window/s"
      }

      /* Obtaining the output from alternate sliding window profile computation */
      let slidingB = location.state?.slidingB;
      /* Removing the first 2 characters */
      slidingB = slidingB.slice(2);
      /* Splitting the content on ], to get the computed profiles */
      let xB = slidingB.split('],');
      /* Splitting the content on }, to get an array of individual sets of profiles */
      let dB = xB[0].split('},');

      /* Instantiating windows as the unsplit resulting windows of text from above split */
      let windowsB = xB[1];
      /* Remove first 2 undesired characters */
      windowsB = windowsB.slice(2);
      /* Remove the final 3 characters */
      windowsB = windowsB.slice(0, -3);
      /* Splitting windows into individual text windows, as the windows can end in 's, "s and escaped "s matching any occurences */
      let eB = windowsB.match(/('[^']*'|"[^"]*"|\\"[^\\"]*\\")+/g);
      for (let x=0; x < eB.length; x++) {
        eB[x] = eB[x].slice(1); // Remove the starting quote mark
        eB[x] = eB[x].slice(0, -1); // Remove the end quote mark
      }
      
      /* Array to store computed profiles of sliding text windows */
      const compslideB: any[] = [];

      /* With the obtained sliding text windows from API, parse as JSON after processing to remove \n and occurences of ' instead of " */
      for (let x=0; x < d.length; x++) {
        if (x !== dB.length - 1) {
          dB[x] = dB[x] + '}';
        }
        dB[x] = dB[x].trim();
        dB[x] = dB[x].replace(/'/g, '"');
        compslideB.push(JSON.parse(dB[x]));
      }

      /* To indicate whenever a discrepancy has occurred, with the exception of character level n-gram analysis,
         I replicate the arrays of data and then change values at indexs to indicate a discrepancy over instantiating
         new arrays and then needing a longer method to establish what has a discrepancy and where
      */

      /* Alternate discrepancies for usual metrics */
      let discrepanciesB = JSON.parse(JSON.stringify(compslideB));
      /* Instantiated arrays to store the minimum and maximum values of each metric */
      let minimumsB = JSON.parse(JSON.stringify(compslideB));
      let maximumsB = JSON.parse(JSON.stringify(compslideB));
      /* Instantiated arrays to store discrepancies with the above */
      let minimumsDiscrepanciesB = JSON.parse(JSON.stringify(minimumsB));
      let maximumsDiscrepanciesB = JSON.parse(JSON.stringify(maximumsB));    

      /* For each metric compute the minimum value across the text windows */
      for (let x=0; x < metrics.length; x++) {
        let min = compslideB[0][metrics[x]];
        for (let y=0; y < compslideB.length; y++) {
          if (compslideB[y][metrics[x]] < min) {
            min = compslideB[y][metrics[x]];
          }
        }
        for (let z=0; z < compslideB.length; z++) {
          if (compslideB[z][metrics[x]] === min) {
            /* Set the minimums array value at this index to 'Minimum' */
            minimumsB[z][metrics[x]] = 'Minimum';
            /* Break once found to avoid duplicates */
            break;
          }
        }
      }

      for (let x=0; x < metrics.length; x++) {
        let max = compslideB[0][metrics[x]];
        for (let z=0; z < compslideB.length; z++) {
          if (compslide[z][metrics[x]] > max) {
            max = compslideB[z][metrics[x]];
          }
        }
        for (let z=0; z < compslideB.length; z++) {
          if (compslideB[z][metrics[x]] === max) {
            /* Set the maximums array value at this index to 'Maximum' */
            maximumsB[z][metrics[x]] = 'Maximum';
            /* Break once found to avoid duplicates */
            break;
          }
        }
      }

      /* Now we are going to identify for each metric any discrepancies that have arisen */
      for (let x=0; x < metrics.length; x++) {
        /* For all computed profiles in each text window */
        for (let y=0; y < compslideB.length; y++) {
          /* If the value of the metric in question is more than the average value of the metric across
             all text windows multiplied by a threshold or if the value is less than the average value
             multiplied by a threshold for that specific value then..
          */          
          if ((compslideB[y][metrics[x]] / averageLength > (averages[x] / averageLength * (1 + weights[x]))) || (compslideB[y][metrics[x]] / averageLength < (averages[x] / averageLength * (1 - weights[x])))) {
              /* We set the cloned compslide array to indicate there is a discrepancy of this metric within such window */
            discrepanciesB[y][metrics[x]] = 'Discrepancy';
          }

          /* Find the minimum value of the metric in any window */
          let minimumValue = 0;
          for (let z=0; z < compslide.length; z++) {
            if (minimums[z][metrics[x]] === 'Minimum') {
              minimumValue = compslide[z][metrics[x]];
              break;
            }
          }

          /* Find the maximum value of the metric in any window */
          let maximumValue = 0;
          for (let z=0; z < compslide.length; z++) {
            if (maximums[z][metrics[x]] === 'Maximum') {
              maximumValue = compslide[z][metrics[x]];
              break;
            }
          }

          /* If the value of the metric is more than or less than the minimum value in any window multiplied by
             an appropriate extreme weight to indicate there is a substantial discrepancy then..
          */
          if ((compslideB[y][metrics[x]] < (minimumValue * (1 - extremeWeights[x])))) {
            /* Indicate that there is a discrepancy */
            minimumsDiscrepanciesB[y][metrics[x]] = 'ExtremeMinimumDiscrepancy';
          }

          if ((compslideB[y][metrics[x]] > (maximumValue * (1 + extremeWeights[x])))) {
            maximumsDiscrepanciesB[y][metrics[x]] = 'ExtremeMaximumDiscrepancy';
          }
        }
      }

      /* Key array that stores HTML output of discrepancy analysis */
      const textWindowsB = [];
      /* Instantiating booleans that will be checks for if a certain discrepancy has occurred 
         and then if so we would like to highlight this to the end user
      */
      let discrepancyB = false;
      let minimumDiscrepancyB = false;
      let maximumDiscrepancyB = false;

      /* Across all windows of text */
      for (let x=0; x < eB.length; x++) {
        let found = false;
        for (let z=0; z < metrics.length; z++) {
          /* If we find a discrepancy then update found and discrepancy */
          if (discrepanciesB[x][metrics[z]] === 'Discrepancy') {
            found = true;
            discrepancyB = true;
          }
          if (minimumsDiscrepanciesB[x][metrics[z]] === 'ExtremeMinimumDiscrepancy') {
            discrepancy = true;
            minimumDiscrepancyB = true;
          }
          if (maximumsDiscrepanciesB[x][metrics[z]] === 'ExtremeMaximumDiscrepancy') {
            discrepancy = true;
            maximumDiscrepancyB = true;
          }
        }

        /* Identical logic for character level n-gram discrepancy analysis */
        let foundCharB = false;
        let foundCharT = false;
        let foundCharU = false;
        const threshold = 2;
        if (cBB[x] > (cB[x] + threshold) || cBB[x] < (cB[x] - threshold)) {
          discrepancy = true;
          foundCharB = true;
        } else if (cTB[x] > (cT[x] + threshold) || cTB[x] < (cT[x] - threshold)) {
          discrepancy = true;
          foundCharT = true;
        } else if (cUB[x] > (cU[x] + threshold) || cUB[x] < (cU[x] - threshold)) {
          discrepancy = true;
          foundCharU = true;
        }
        /* If we have a discrepancy */
        if (found || foundCharB || foundCharT || foundCharU) {          
          /* Queue up a new HTML div that will indicate this under profileStatistics,
             this also outputs the text window content 
          */ 
          textWindowsB.push(
            <div className='profileStatistics'>
              <b>Window {x + 1}</b>
              <div className="profileStatisticsDiscrepancy">
                <p>{eB[x]}</p>
              </div>
              <b>Discrepancies</b>
            </div>
          )
          /* For each metric */
          for(let z=0; z < metrics.length; z++) {
            /* Prepare HTML output displaying what the metric is and the value */
            let metric = <p className='profileStatisticsDiscrepancy'>{metricsFancy[z]}: <b>{compslideB[x][metrics[z]] / averageLength}</b></p>;
            /* Expected value range of lowest to highest */
            let output = <p>(Expected: {averages[z] / averageLength * (1 - weights[z])} - {averages[z] / averageLength * (1 + weights[z])})</p>;
            /* If in the discrepancies array we detect a discrepancy or in the minimum or maximum then.. */
            if ((discrepanciesB[x][metrics[z]] === 'Discrepancy') || (minimumsDiscrepanciesB[x][metrics[z]] === 'ExtremeMinimumDiscrepancy') || (maximumsDiscrepanciesB[x][metrics[z]] === 'ExtremeMaxmimumDiscrepancy')) {
              /* If the discrepancy is a minimum then obtain the minimum value range and output this */
              if (minimumsDiscrepanciesB[x][metrics[z]] === 'ExtremeMinimumDiscrepancy') {
                let minimumValue = 0;
                for (let a=0; a < compslide.length; a++) {
                  if (minimums[a][metrics[z]] === 'Minimum') {
                    minimumValue = compslide[a][metrics[z]];
                    break;
                  }
                }
                metric = <p className='profileStatisticsDiscrepancy'>Minimum {metricsFancy[z]}: <b>{compslideB[x][metrics[z]]}</b></p>;
                output = <p>(Expected more than: {minimumValue * (1 - extremeWeights[z])})</p>;
              } 
              /* If the discrepancy is a maximum then obtain the maximum range and output this */
              if (maximumsDiscrepanciesB[x][metrics[z]] == 'ExtremeMaximumDiscrepancy') {
                let maximumValue = 0;
                for (let b=0; b < compslide.length; b++) {
                  if (maximums[b][metrics[z]] === 'Maximum') {
                    maximumValue = compslide[b][metrics[z]];
                    break;
                  }
                }
                metric = <p className='profileStatisticsDiscrepancy'>Maximum {metricsFancy[z]}: <b>{compslideB[x][metrics[z]]}</b></p>;
                output = <p>(Expected lesser than: {maximumValue * (1 + extremeWeights[z])})</p>;
              }
              /* Here we push to the array of HTML code divs our output that has been built above */
              textWindowsB.push(
                <div className='profileStatistics'>
                  {metric}
                  {output}
                </div>
              )
            }
          }
          /* Similar logic with character level n-gram discrepancies */
          if (foundCharB) {
            textWindowsB.push(
              <div className='profileStatistics'>
                <p className='profileStatisticsDiscrepancy'>Matching Character Bigrams (Top 10): <b>{cBB[x]}</b></p>  
                <p>(Expected: {(cB[x] - threshold)} - {(cB[x] + threshold)})</p>                  
              </div>
            )
          } else if (foundCharT) {
            textWindowsB.push(
              <div className='profileStatistics'>
                <p className='profileStatisticsDiscrepancy'>Matching Character Trigrams (Top 10): <b>{cTB[x]}</b></p>  
                <p>(Expected: {(cT[x] - threshold)} - {(cB[x] + threshold)})</p>                  
              </div>
            )
          } else if (foundCharU) {
            textWindowsB.push(
              <div className='profileStatistics'>
                <p className='profileStatisticsDiscrepancy'>Matching Character Unigrams (Top 10): <b>{cUB[x]}</b></p>  
                <p>(Expected: {(cU[x] - threshold)} - {(cB[x] + threshold)})</p>                  
              </div>
            )
          }
        } else {
          textWindowsB.push(
            <div className='profileStatistics'>
              <b>Window {x + 1}</b>
              <div className="profileStatisticsNoDiscrepancy">
                <p>{eB[x]}</p>
              </div>
            </div>
          )
        }
      }

      /* Instantiate the small text that accompanies the analysis */
      let discrepancyTextB= "";
      /* Dependant on the state of discrepancy then set text.. */
      if (discrepancyB == false) {
        discrepancyTextB = "Comparison has consistent writing profile"
      } else {
        discrepancyTextB = "Comparison has at least one discrepancy in writing profile, please review the suspected window/s"
      }

      /* 
       * The following code is to render React Charts to display the metric results of analysis to the end user,
       * the initial chart displays metrics for the profile as a whole piece of text and not segregated into series
       * that represent each sliding window. Implemented following documentation from below

       * “Getting Started,” react-charts.tanstack.com. https://react-charts.tanstack.com/docs/getting-started.
       */
    
      /* React Charts uses a datum type to validate the data fields, fields must correspond with this */
      type MyDatum = { metric: String, result: number}

      /* Function which will render React Charts chart, label will be the sample method */
      const ComparisonCompute = function() {
        const data = [
          {
            label: comparison,
            data: [
              {
                metric: 'Average archaic words',
                result: comparisonCompute.avgArchaicWords,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Average function words',
                result: comparisonCompute.avgFunctionWords,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Average misspelt words',
                result: comparisonCompute.avgMisspeltWords,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Average sentence length',
                result: comparisonCompute.avgSentenceLength,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Average token length',
                result: comparisonCompute.avgTokenLength,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Bigrams',
                result: comparisonCompute.bigrams,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Character Bigrams',
                result: comparisonCompute.characterBigrams,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Character Trigrams',
                result: comparisonCompute.characterTrigrams,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Character Unigrams',
                result: comparisonCompute.characterUnigrams,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Lexical Richness',
                result: comparisonCompute.lexicalRichness,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Sentences',
                result: comparisonCompute.sentences,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Tokens',
                result: comparisonCompute.tokens,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Trigrams',
                result: comparisonCompute.trigrams,
              },
            ],
          },
          {
            label: comparison,
            data: [
              {
                metric: 'Unigrams',
                result: comparisonCompute.unigrams,
              },
            ],
          },
        ]

        /* Setting the x-axis type */
        const primaryAxis = React.useMemo(
          (): AxisOptions<MyDatum> => ({
            getValue: datum => datum.metric,
          }),
          []
        )

        /* Setting the y-axis type */
        const secondaryAxes = React.useMemo(
          (): AxisOptions<MyDatum>[] => [
            {
              getValue: datum => datum.result,
              /* Bar chart desired */
              elementType: 'bar',
            },
          ],
          []
        )

        /* Initial height and width will be overriden by CSS properties */
        return (
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
              initialHeight: 200,
              initialWidth: 200,
            }}
          />
        )
      }

      /* Function which will render React Charts chart, label will be the sample method */
      const PresetCompute = function() {
        const data = [
          {
            label: sample,
            data: [
              {
                metric: 'Average archaic words',
                result: presetCompute.avgArchaicWords,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Average function words',
                result: presetCompute.avgFunctionWords,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Average misspelt words',
                result: presetCompute.avgMisspeltWords,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Average sentence length',
                result: presetCompute.avgSentenceLength,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Average token length',
                result: presetCompute.avgTokenLength,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Bigrams',
                result: presetCompute.bigrams,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Character Bigrams',
                result: presetCompute.characterBigrams,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Character Trigrams',
                result: presetCompute.characterTrigrams,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Character Unigrams',
                result: presetCompute.characterUnigrams,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Lexical Richness',
                result: presetCompute.lexicalRichness,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Sentences',
                result: presetCompute.sentences,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Tokens',
                result: presetCompute.tokens,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Trigrams',
                result: presetCompute.trigrams,
              },
            ],
          },
          {
            label: sample,
            data: [
              {
                metric: 'Unigrams',
                result: presetCompute.unigrams,
              },
            ],
          },
        ]

        /* Setting the x-axis type */
        const primaryAxis = React.useMemo(
          (): AxisOptions<MyDatum> => ({
            getValue: datum => datum.metric,
          }),
          []
        )

        /* Setting the y-axis type */
        const secondaryAxes = React.useMemo(
          (): AxisOptions<MyDatum>[] => [
            {
              getValue: datum => datum.result,
              /* Bar chart desired */
              elementType: 'bar',
            },
          ],
          []
        )

        /* Initial height and width will be overriden by CSS properties */
        return (
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
              initialHeight: 200,
              initialWidth: 200,
            }}
          />
        )
      }

      /* Function to render the React Charts chart for the sliding text windows profile computation */
      const SlidingWindows = function() {
        /* Instantiating array to hold the data for each window */
        const data = []   
        
        /* For each sliding text window.. */
        for (let x=0; x < compslide.length; x++) {
          data.push(
            {
              label: "Window " + (x + 1),
              data: [
                {
                  metric: 'Average archaic words',
                  result: compslide[x].avgArchaicWords,
                },
                {
                  metric: 'Average function words',
                  result: compslide[x].avgFunctionWords,
                },
                {
                  metric: 'Average misspelt words',
                  result: compslide[x].avgMisspeltWords,
                },
                {
                  metric: 'Average sentence length',
                  result: compslide[x].avgSentenceLength,
                },
                {
                  metric: 'Average token length',
                  result: compslide[x].avgTokenLength,
                },
                {
                  metric: 'Bigrams',
                  result: compslide[x].bigrams,
                },
                {
                  metric: 'Character Bigrams',
                  result: compslide[x].characterBigrams,
                },
                {
                  metric: 'Character Trigrams',
                  result: compslide[x].characterTrigrams,
                },
                {
                  metric: 'Character Unigrams',
                  result: compslide[x].characterUnigrams,
                },
                {
                  metric: 'Lexical Richness',
                  result: compslide[x].lexicalRichness,
                },
                {
                  metric: 'Sentences',
                  result: compslide[x].sentences,
                },
                {
                  metric: 'Tokens',
                  result: compslide[x].tokens,
                },
                {
                  metric: 'Trigrams',
                  result: compslide[x].trigrams,
                },
                {
                  metric: 'Unigrams',
                  result: compslide[x].unigrams,
                },
              ],
            },
          )
        }

        const primaryAxis = React.useMemo(
          (): AxisOptions<MyDatum> => ({
            /* Bar chart desired */
            getValue: (datum) => datum.metric,
          }),
          []
        );
      
        const secondaryAxes = React.useMemo(
          (): AxisOptions<MyDatum>[] => [
            {
              getValue: (datum) => datum.result,
              elementType: 'bar',
            },
          ],
          []
        );
      
        /* Initial height and width will be overriden by CSS properties */
        return (
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
              initialHeight: 200,
              initialWidth: 200,
            }}
          />
        );
      }

      /* Function to render the React Charts chart for the sliding text windows profile computation */
      const SlidingWindowsB = function() {
        /* Instantiating array to hold the data for each window */
        const data = []   
        
        /* For each sliding text window.. */
        for (let x=0; x < compslideB.length; x++) {
          data.push(
            {
              label: "Window " + (x + 1),
              data: [
                {
                  metric: 'Average archaic words',
                  result: compslideB[x].avgArchaicWords,
                },
                {
                  metric: 'Average function words',
                  result: compslideB[x].avgFunctionWords,
                },
                {
                  metric: 'Average misspelt words',
                  result: compslideB[x].avgMisspeltWords,
                },
                {
                  metric: 'Average sentence length',
                  result: compslideB[x].avgSentenceLength,
                },
                {
                  metric: 'Average token length',
                  result: compslideB[x].avgTokenLength,
                },
                {
                  metric: 'Bigrams',
                  result: compslideB[x].bigrams,
                },
                {
                  metric: 'Character Bigrams',
                  result: compslideB[x].characterBigrams,
                },
                {
                  metric: 'Character Trigrams',
                  result: compslideB[x].characterTrigrams,
                },
                {
                  metric: 'Character Unigrams',
                  result: compslideB[x].characterUnigrams,
                },
                {
                  metric: 'Lexical Richness',
                  result: compslideB[x].lexicalRichness,
                },
                {
                  metric: 'Sentences',
                  result: compslideB[x].sentences,
                },
                {
                  metric: 'Tokens',
                  result: compslideB[x].tokens,
                },
                {
                  metric: 'Trigrams',
                  result: compslideB[x].trigrams,
                },
                {
                  metric: 'Unigrams',
                  result: compslideB[x].unigrams,
                },
              ],
            },
          )
        }

        const primaryAxis = React.useMemo(
          (): AxisOptions<MyDatum> => ({
            getValue: (datum) => datum.metric,
          }),
          []
        );
      
        const secondaryAxes = React.useMemo(
          (): AxisOptions<MyDatum>[] => [
            {
              getValue: (datum) => datum.result,
              /* Bar chart desired */
              elementType: 'bar',
            },
          ],
          []
        );
      
        /* Initial height and width will be overriden by CSS properties */
        return (
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
              initialHeight: 200,
              initialWidth: 200,
            }}
          />
        );
      }
    return (
      /* Main component HTML content, components such as Navbar rendered and React Charts charts present */
      <div>
        <Navbar />
        <div className="titleSubtitleReviewAnalysis">
          <h1>Review Analysis</h1>
          <p>See statistics of the profile, review highlighted descrepancies</p>
        </div>
        <div className="profileInformation">
          <p>Sample: <b>{sample}</b></p>
          <p>Comparison: <b>{comparison}</b></p>
        </div>
        <div className="wrapperProfileStatistics">
          <div className="profileStatistics">
            <p><b>Sample Profile</b></p>
            <p>Key Statistics</p>
            <p>Average archaic words: <b>{presetCompute.avgArchaicWords}</b></p>
            <p>Average function words: <b>{presetCompute.avgFunctionWords}</b></p>
            <p>Average misspelt words: <b>{presetCompute.avgMisspeltWords}</b></p>
            <p>Average sentence length: <b>{presetCompute.avgSentenceLength}</b></p>
            <p>Average token length: <b>{presetCompute.avgTokenLength}</b></p>
            <p>Bigrams: <b>{presetCompute.bigrams}</b></p>
            <p>Character Bigrams: <b>{presetCompute.characterBigrams}</b></p>
            <p>Character Trigrams: <b>{presetCompute.characterTrigrams}</b></p>
            <p>Character Unigrams: <b>{presetCompute.characterUnigrams}</b></p>
            <p>Lexical Richness: <b>{presetCompute.lexicalRichness}</b></p>
            <p>Sentences: <b>{presetCompute.sentences}</b></p>
            <p>Tokens: <b>{presetCompute.tokens}</b></p>
            <p>Trigrams: <b>{presetCompute.trigrams}</b></p>
            <p>Unigrams: <b>{presetCompute.unigrams}</b></p>
            <div className="wrapperChart">
              <PresetCompute></PresetCompute>
            </div>
            <p><b>Sample Profile</b></p>
            <p>Profile Discrepancies</p>
            <p>{discrepancyText}</p>
            {textWindows}
            <div className="wrapperChart">
              <SlidingWindows></SlidingWindows>
            </div>
          </div>
          <div className="profileStatistics">
            <p><b>Comparison Profile</b></p>
            <p>Key Statistics</p>
            <p>Average archaic words: <b>{comparisonCompute.avgArchaicWords}</b></p>
            <p>Average function words: <b>{comparisonCompute.avgFunctionWords}</b></p>
            <p>Average misspelt words: <b>{comparisonCompute.avgMisspeltWords}</b></p>
            <p>Average sentence length: <b>{comparisonCompute.avgSentenceLength}</b></p>
            <p>Average token length: <b>{comparisonCompute.avgTokenLength}</b></p>
            <p>Bigrams: <b>{comparisonCompute.bigrams}</b></p>
            <p>Character Bigrams: <b>{comparisonCompute.characterBigrams}</b></p>
            <p>Character Trigrams: <b>{comparisonCompute.characterTrigrams}</b></p>
            <p>Character Unigrams: <b>{comparisonCompute.characterUnigrams}</b></p>
            <p>Lexical Richness: <b>{comparisonCompute.lexicalRichness}</b></p>
            <p>Sentences: <b>{comparisonCompute.sentences}</b></p>
            <p>Tokens: <b>{comparisonCompute.tokens}</b></p>
            <p>Trigrams: <b>{comparisonCompute.trigrams}</b></p>
            <p>Unigrams: <b>{comparisonCompute.unigrams}</b></p>
            <div className="wrapperChart">
              <ComparisonCompute></ComparisonCompute>
            </div>
            <p><b>Comparison Profile</b></p>
            <p>Profile Discrepancies</p>
            <p>{discrepancyTextB}</p>
            {textWindowsB}
            <div className="wrapperChart">
              <SlidingWindowsB></SlidingWindowsB>
            </div>
          </div>
        </div>      
      </div>
    );
  }
}

export default ReviewAnalysis;
