// Importing Link class from React Router so can navigate using the nav bar
import { Link } from "react-router-dom";

// Function to display the nav bar when called
function Navbar() {
  return (
    <div className="navbar">
      <ul>
        <li><p>Stylometric Analysis</p></li>
        <li><Link to="/">Home</Link></li>
      </ul>
    </div>
  );
}

export default Navbar;
