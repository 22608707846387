// Importing stylesheet
import "./components.css";
// Importing navigation bar
import Navbar from './Navbar';
// Importing link for the two buttons on home component
import { Link } from 'react-router-dom';

// Component as landing page/index
function Home() {
  return (
    <div>
      <Navbar />
      <div className="titleSubtitle">
        <h1>Welcome</h1>
        <p>Select a preset or upload a file for analysis</p>
        <ul>
          <li><Link to="/presets">Presets</Link></li>
          <li><Link to="/upload">Upload</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
