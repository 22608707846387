import React, { useState } from 'react';
import axios from 'axios';
import "./components.css";
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';

/**
 * Axios usage following get started documentation below
 * 
 * “Axios,” axios-http.com. https://axios-http.com/
 */

function Upload() {
  const [comparison, setComparison] = useState("chatgptPrompt");
  const [compute, setCompute] = useState("");
  const [fileUpload, setFileUpload] = useState(false);
  const [fileUploadFile, setFileUploadFile] = useState<File | null>(null);

  const [comparisonFileUpload, setComparisonFileUpload] = useState(false);
  const [comparisonFileUploadFile, setComparisonFileUploadFile] = useState<File | null>(null);

  const [chatgptPrompt, setChatgptPrompt] = useState(true);
  const [chatgptPromptText, setChatgptPromptText] = useState("");


  const [freeTextEntry, setFreeTextEntry] = useState(false);
  const [freeTextEntryText, setFreeTextEntryText] = useState("");
  const [preset, setPreset] = useState("austen");
  const [textWindow, setTextWindow] = useState("");
  const [comparisonCompute, setcomparisonCompute] = useState("");
  const [comparisonTextWindow, setComparisonTextWindow] = useState("");
  const [presetCompute, setPresetCompute] = useState("");
  const [presetTextWindow, setPresetTextWindow] = useState("");
  let comparisonFileName = "";
  let fileName = "";

  const [sliding, setSliding] = useState("");
  const [slidingB, setSlidingB] = useState("");


  const navigate = useNavigate();

  const handleChangeComparison = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setComparison(event.target.value);
    setChatgptPrompt(event.target.value === 'chatgptPrompt')
    setFreeTextEntry(event.target.value === 'freeTextEntry');
    setFileUpload(event.target.value === 'fileUpload');
  }

  const handleChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setFileUploadFile(file);
  };

  const handleChangeComparisonFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setComparisonFileUploadFile(file);
  };

  const handleChangeChatgptPrompt = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setChatgptPromptText(event.target.value);
  }

  const handleChangeFreeTextEntry = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setFreeTextEntryText(event.target.value);
  }

  const submit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      const upload = new FormData();
      if (fileUploadFile) {
        upload.append('file', fileUploadFile);
        const response = await axios.post('api/file-upload.php', upload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        fileName = response.data.fileName;
      }
      const upload2 = new FormData();
      if (comparisonFileUploadFile) {
        upload2.append('file', comparisonFileUploadFile);
        const response = await axios.post('api/file-upload.php', upload2, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        comparisonFileName = response.data.fileName;
      }
    } catch(error) {
      console.error(error);
    }
    axios.get("api/perform-analysis.php", {
      params: {
        chatgptPrompt: chatgptPromptText,
        comparison: comparison,
        freeTextEntry: freeTextEntryText,
        comparisonFileUpload: comparisonFileName,
        fileUpload: fileName,
        preset: 'fileUpload'
      }
    })
    .then(response => {
      if (comparison == 'noComparison') {
        const compute = response.data.presetCompute;
        const sliding = response.data.computeWindows;
        setCompute(compute);
        setSliding(sliding);
        navigate("/reviewanalysisnocomparison", { state: { comparison, compute, textWindow, sample: "File upload", sliding } });
      } else {
        const presetCompute = response.data.presetCompute;
        const comparisonCompute = response.data.comparisonCompute;
        const sliding = response.data.computeWindows;
        const slidingB = response.data.computeWindowsB;
        setCompute(presetCompute);
        setCompute(comparisonCompute);
        setSliding(sliding);
        setSlidingB(slidingB);
        navigate("/reviewanalysis", { state: { comparison, comparisonCompute, comparisonTextWindow, presetCompute, presetTextWindow, sample: "File upload", sliding, slidingB } });
      }
    })
    .catch(error => {
      console.error(error);
    });
  }
  return (
    <div>
      <Navbar />
      <div className="titleSubtitle">
        <h1>File Uploader</h1>
        <p>Upload your own file (e.g .docx, .pdf) for the software to perform analysis on</p>
        <div className="wrapperSelects">
          <div className="selects">
            <p>1. Choose file</p>
            <div>
              <input onChange={handleChangeFileUpload} type="file"/>
            </div>
            <p className="smallParagraph">Note: When selecting your own file, it's possible to perform analysis on<br />just the file or compare the analysis with another sample</p>
          </div>
          <div className="selects">
            <p>2. Select comparison method</p>
            <select value={comparison} onChange={handleChangeComparison}>
              <option value="chatgptPrompt">ChatGPT prompt</option>
              <option value="freeTextEntry">Free text entry</option>
              <option value="fileUpload">File upload</option>
              <option value="noComparison">No comparison</option>
            </select>
            {chatgptPrompt && (
            <div>
              <input className="freeTextEntry" type="text" onChange={handleChangeChatgptPrompt} value={chatgptPromptText}/>
            </div>
            )}
            {freeTextEntry && (
            <div>
              <input className="freeTextEntry" type="text" onChange={handleChangeFreeTextEntry} value={freeTextEntryText}/>
            </div>
            )}
            {fileUpload && (
            <div>
              <input onChange={handleChangeComparisonFileUpload} type="file"/>
            </div>
            )}
          </div>
        </div>
        <form>
          <button onClick={submit}>Perform Analysis</button>
        </form>
      </div>
    </div>
  )
}

export default Upload;
