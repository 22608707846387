import React, { useState } from 'react';
import axios from 'axios';
import "./components.css";
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';

/**
 * Axios usage following get started documentation below
 * 
 * “Axios,” axios-http.com. https://axios-http.com/
 */

function Presets() {
  const [comparison, setComparison] = useState("chatgptThemedEssay");
  const [compute, setCompute] = useState("");
  const [fileUpload, setFileUpload] = useState(false);
  const [fileUploadFile, setFileUploadFile] = useState<File | null>(null);
  const [freeTextEntry, setFreeTextEntry] = useState(false);
  const [freeTextEntryText, setFreeTextEntryText] = useState("");
  const [preset, setPreset] = useState("austen");
  const [textWindow, setTextWindow] = useState("");
  const [comparisonCompute, setcomparisonCompute] = useState("");
  const [comparisonTextWindow, setComparisonTextWindow] = useState("");
  const [presetCompute, setPresetCompute] = useState("");
  const [presetTextWindow, setPresetTextWindow] = useState("");
  let fileName = "";
  
  const [sliding, setSliding] = useState("");
  const [slidingB, setSlidingB] = useState("");

  const navigate = useNavigate();

  const handleChangeComparison = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setComparison(event.target.value);
    setFreeTextEntry(event.target.value === 'freeTextEntry');
    setFileUpload(event.target.value === 'fileUpload');
  }

  const handleChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setFileUploadFile(file);
  };

  const handleChangeFreeTextEntry = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setFreeTextEntryText(event.target.value);
  }

  const handleChangePreset = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setPreset(event.target.value);
  }

  const submit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      const upload = new FormData();
      if (fileUploadFile) {
        upload.append('file', fileUploadFile);
        const response = await axios.post('api/file-upload.php', upload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        fileName = response.data.fileName;
      }
    } catch(error) {
      console.error(error);
    }
    axios.get("api/perform-analysis.php", {
      params: {
        comparison: comparison,
        freeTextEntry: freeTextEntryText,
        fileUpload: fileName,
        preset: preset
      }
    })
    .then(response => {
      if (comparison == 'noComparison') {
        const compute = response.data.compute;
        const textWindow = response.data.textWindow;
        const sliding = response.data.computeWindows;
        setCompute(compute);
        setTextWindow(textWindow);
        setSliding(sliding);
        navigate("/reviewanalysisnocomparison", { state: { comparison, compute, textWindow, sample: "Preset", sliding } });
      } else {
        const presetCompute = response.data.presetCompute;
        const presetTextWindow = response.data.presetTextWindow;
        const comparisonCompute = response.data.comparisonCompute;
        const comparisonTextWindow = response.data.comparisonTextWindow;
        const sliding = response.data.computeWindows;
        const slidingB = response.data.computeWindowsB;
        setPresetCompute(presetCompute);
        setPresetTextWindow(presetTextWindow);
        setcomparisonCompute(comparisonCompute);
        setComparisonTextWindow(comparisonTextWindow);
        setSliding(sliding);
        setSlidingB(slidingB);
        navigate("/reviewanalysis", { state: { comparison, comparisonCompute, comparisonTextWindow, presetCompute, presetTextWindow, sample: "Preset", sliding, slidingB } });
      }
    })
    .catch(error => {
      console.error(error);
    });
  }
  return (
    <div>
      <Navbar />
      <div className="titleSubtitle">
        <h1>Presets</h1>
        <p>Choose from a range of presets to compare writing profiles with a sample</p>
        <div className="wrapperSelects">
          <div className="selects">
            <p>1. Select preset</p>
            <select value={preset} onChange={handleChangePreset}>
              <option value="austen">Austen</option>
              <option value="edgeworth">Edgeworth</option>
              <option value="shakespeare">Shakespeare</option>
            </select>
          </div>
          <div className="selects">
            <p>2. Select comparison method</p>
            <select value={comparison} onChange={handleChangeComparison}>
              <option value="chatgptThemedEssay">ChatGPT themed essay</option>
              <option value="freeTextEntry">Free text entry</option>
              <option value="fileUpload">File upload</option>
              <option value="noComparison">No comparison</option>
            </select>
            {freeTextEntry && (
            <div>
              <input className="freeTextEntry" type="text" onChange={handleChangeFreeTextEntry} value={freeTextEntryText}/>
            </div>
            )}
            {fileUpload && (
            <div>
              <input onChange={handleChangeFileUpload} type="file"/>
            </div>
            )}
          </div>
        </div>
        <form>
          <button onClick={submit}>Perform Analysis</button>
        </form>
      </div>
    </div>
  )
}

export default Presets;
