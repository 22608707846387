// Import Home component
import Home from './components/Home';
// Import Presets component
import Presets from './components/Presets';
// Import ReviewAnalysis component for when more than one analysis will be performed
import ReviewAnalysis from './components/ReviewAnalysis';
// Import ReviewAnalysisNoComparison component for when individual analysis will be performed
import ReviewAnalysisNoComparison from './components/ReviewAnalysisNoComparison';
// Import Upload component
import Upload from './components/Upload';
// Import Route, Routes from React Router to setup app routes and allow navigation to links and such
import { Route, Routes} from 'react-router-dom';

// Base function
function App() {
  return (
    <>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="presets" element={<Presets />} />
        <Route path="reviewanalysis" Component={ReviewAnalysis} />
        <Route path="reviewanalysisnocomparison" Component={ReviewAnalysisNoComparison} />
        <Route path="upload" element={<Upload />} />
      </Routes>
    </>
  );
}

export default App;
